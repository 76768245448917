export interface LeadSegmentCounter {
	counter_id: number;
	domain: string;
	id: number;
	name: string;
	type_counter: LeadSegmentCounterType;
}

export const LeadSegmentCounterType = {
	Default: 1,
	Alternative: 2,
} as const;

export type LeadSegmentCounterType =
	(typeof LeadSegmentCounterType)[keyof typeof LeadSegmentCounterType];

export const LeadSegmentCounterCode = {
	Default: 'default',
	Alternative: 'alternative',
	All: 'all',
} as const;

export type LeadSegmentCounterCode =
	(typeof LeadSegmentCounterCode)[keyof typeof LeadSegmentCounterCode];
