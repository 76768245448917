export interface LeadAuditoryType {
	code: LeadAuditoryCode;
	id: number;
	name: string;
	sort: number;
}

export const LeadAuditoryCode = {
	Scoring: 'scoring',
	Status: 'status',
	Conversion: 'conversion',
} as const;

export type LeadAuditoryCode =
	(typeof LeadAuditoryCode)[keyof typeof LeadAuditoryCode];
