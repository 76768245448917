import { TableColumn } from '@eui/table';

import { LeadStatus } from './lead-status.interface';

export type LeadsTableCell = string | number | LeadStatus | null;

export const LEADS_TABLE_HEADERS: TableColumn[][] = [
	[
		{
			id: 'new_lead',
			property: 'new_lead',
			title: '',
			resizable: false,
			fixed: true,
			minWidth: 40,
		},
		{
			id: 'scoring',
			property: 'scoring',
			title: '',
			resizable: false,
			fixed: true,
			minWidth: 40,
		},
		{
			id: 'name',
			property: 'name',
			title: 'ФИО',
			resizable: true,
			fixed: true,
			minWidth: 240,
		},
		{
			id: 'phone',
			property: 'phone',
			title: 'Телефон',
			resizable: false,
			fixed: true,
			minWidth: 140,
		},
		{
			id: 'sent',
			property: 'sent',
			title: 'Дата заявки',
			resizable: false,
			fixed: false,
			minWidth: 124,
		},
		{
			id: 'need_status',
			property: 'need_status',
			title: 'Статус заявки',
			resizable: false,
			fixed: false,
			minWidth: 240,
		},
		{
			id: 'order',
			property: 'order',
			title: 'Форма заявки',
			resizable: true,
			fixed: false,
			minWidth: 400,
		},
		{
			id: 'all_fileds',
			property: 'all_fileds',
			title: 'Доп. данные',
			resizable: false,
			fixed: false,
			minWidth: 104,
		},
		{
			id: 'http_referer',
			property: 'http_referer',
			title: 'Источник',
			resizable: false,
			fixed: false,
			minWidth: 148,
		},
		{
			id: 'utm_source',
			property: 'utm_source',
			title: 'utm_source',
			resizable: false,
			fixed: false,
			minWidth: 96,
		},
		{
			id: 'utm_campaign',
			property: 'utm_campaign',
			title: 'utm_campaign',
			resizable: false,
			fixed: false,
			minWidth: 160,
		},
		{
			id: 'call_1',
			property: 'call_1',
			title: 'Звонок №1',
			resizable: false,
			fixed: false,
			minWidth: 364,
			colspan: 2,
		},
		{
			id: 'call_2',
			property: 'call_2',
			title: 'Звонок №2',
			resizable: false,
			fixed: false,
			minWidth: 364,
			colspan: 2,
		},
		{
			id: 'call_3',
			property: 'call_3',
			title: 'Звонок №3',
			resizable: false,
			fixed: false,
			minWidth: 364,
			colspan: 2,
		},
		{
			id: 'call_4',
			property: 'call_4',
			title: 'Звонок №4',
			resizable: false,
			fixed: false,
			minWidth: 364,
			colspan: 2,
		},
		{
			id: 'call_5',
			property: 'call_5',
			title: 'Звонок №5',
			resizable: false,
			fixed: false,
			minWidth: 364,
			colspan: 2,
		},
		{
			id: 'application_ries',
			property: 'application_ries',
			title: 'Заявка',
			resizable: false,
			fixed: false,
			minWidth: 160,
			info: 'ID заявки появится здесь примерно через сутки после занесения в RIES или Космос, если лид был с потребностью.',
		},
		{
			id: 'rieltor',
			property: 'rieltor',
			title: 'Риэлтор',
			resizable: true,
			fixed: false,
			minWidth: 360,
		},
		{
			id: 'comment',
			property: 'comment',
			title: 'Комментарий',
			resizable: false,
			fixed: false,
			minWidth: 112,
		},
		{
			id: 'id',
			property: 'id',
			title: 'ID',
			resizable: false,
			fixed: false,
			minWidth: 104,
		},
	],
];
